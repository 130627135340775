<template>
  <div>
    <!-- Task Card Header -->
    <b-card v-if="!isLoading" class="quiz-overview-card  mb-1 p-1">
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar rounded="lg" :variant="taskType(contentType).variant">
            <i class="card-icon " :class="taskType(contentType).icon" />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <span class="text-muted ">
                {{ taskType(contentType).title }}
              </span>
              <h6 class="font-weight-bolder small-gap-bottom">
                {{ quizOverview.name }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col md="3">
          <!-- Teacher Info -->
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Guru Kelas
            </h6>
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="quizOverview.classroom.teacher.avatar"
                  :text="avatarText(quizOverview.classroom.teacher.name)"
                  variant="light-primary"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ quizOverview.classroom.teacher.name }}
              </span>
              <small class="text-muted">{{
                quizOverview.classroom.teacher.school.name
              }}</small>
            </b-media>
          </div>

          <!-- Class Info -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Kelas
            </h6>
            <span>
              {{ quizOverview.classroom.name }}
            </span>
          </div>

          <!-- Quiz Type-->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Tipe Kuis
            </h6>
            <span>
              {{
                taskType(contentType)
                  .title.split(' : ')
                  .pop()
              }}
            </span>
          </div>
        </b-col>
        <b-col>
          <!-- Exam Alert  -->
          <b-alert show variant="warning">
            <div class="alert-body p-2">
              <div class="d-flex">
                <div class="mr-1">
                  <b-avatar rounded="lg" class="bg-white">
                    <i class="card-icon text-warning ti ti-alert-triangle"></i>
                  </b-avatar>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="font-weight-bolder small-gap-bottom text-warning">
                    Perhatian
                  </h5>
                  <span class="font-weight-normal">
                    Sebelum memulai kuis, pastikan kamu sudah siap dan kerjakan
                    kuis secara mandiri, jangan bertanya kepada teman atau orang
                    lain. Awali dengan membaca soal dengan teliti dan berdoa
                    kepada yang maha kuasa agar diberi kemudahan dalam
                    mengerjakan soal.
                  </span>
                </div>
              </div>
            </div>
          </b-alert>

          <!-- Misc -->
          <div>
            <div class="d-flex justify-content-between small-gap-bottom">
              <h6 class="font-weight-bolder small-gap-bottom">
                Progress
              </h6>
              <h6 class="font-weight-bolder small-gap-bottom">
                Waktu Pengerjaan : {{ quizOverview.time }} Menit
              </h6>
            </div>
            <b-progress value="1" max="100" />
            <ul class="regular-ul small-gap-top">
              <li>
                Lihat progress bar ini untuk melihat waktu yang sedang berjalan
              </li>
              <li>
                Kerjakan soal yang paling mudah terlebih dahulu, periksa kembali
                jawaban anda.
              </li>
            </ul>
          </div>

          <!-- Exam Step Info -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Langkah Mengerjakan Kuis
            </h6>
            <ol class="regular-ul">
              <li>Klik “Konfirmasi kesediaan kuis.</li>
              <li>
                Kerjakan sesuai dengan pertanyaan yang ada dan pastikan jawaban
                sudah terisi dengan teliti.
              </li>
              <li>
                Jika masih ragu-ragu terhadap pilihan klik button tandai.
              </li>
              <li>
                Klik tombol “Selesaikan Kuis" di akhir kuis.
              </li>
            </ol>
          </div>
        </b-col>

        <!-- Situational Button -->
        <!-- Is Not Started -->
        <b-col
          v-if="quizStatus === 'isNotStarted'"
          md="12"
          class="border-top pt-1 mt-1"
        >
          <!-- Accept term and condition -->
          <div class="d-flex justify-content-start small-gap-bottom">
            <b-form-checkbox v-model="isAcceptTerm" value="true">
              Konfirmasi kesediaan mengikuti ujian.
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-start">
            <b-button
              variant="outline-danger"
              :to="{
                name: checkAuthorizeRole('classroom-detail-task'),
                params: {
                  classroomId: classroomId,
                  contentId: contentId,
                  taskId: taskId,
                },
              }"
            >
              Batal
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              :disabled="!isAcceptTerm"
              @click="startQuiz"
            >
              <span class="align-middle mr-1">Mulai Kuis</span>
              <i class="align-middle ti ti-arrow-right" />
            </b-button>
          </div>
        </b-col>

        <!-- Is Started -->
        <b-col
          v-if="quizStatus === 'isStarted'"
          md="12"
          class="border-top pt-1 mt-1"
        >
          <div class="d-flex justify-content-start">
            <b-button
              variant="warning"
              class="ml-1"
              :to="{
                name: checkAuthorizeRole('classroom-detail-task-quiz'),
                params: {
                  id: classroomId,
                  contentId: contentId,
                  taskId: taskId,
                  contentType: contentType,
                },
              }"
            >
              <span class="align-middle mr-1">Lanjutkan Kuis</span>
              <i class="align-middle ti ti-arrow-right" />
            </b-button>
          </div>
        </b-col>

        <!-- Is Finished -->
        <b-col
          v-if="quizStatus === 'isFinished'"
          md="12"
          class="border-top pt-1 mt-1"
        >
          <div class="d-flex justify-content-start">
            <b-button variant="success" class="ml-1">
              <span class="align-middle mr-1">Lihat Hasil</span>
              <i class="align-middle ti ti-arrow-right" />
            </b-button>
          </div>
        </b-col>

        <!-- Is Unavailable -->
        <b-col
          v-if="quizStatus === 'isUnavailable'"
          md="12"
          class="border-top pt-1 mt-1"
        >
          <div class="d-flex justify-content-start">
            <b-button variant="outline-secondary" disabled>
              <span class="align-middle">Kuis Telah Berakhir</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Kuis</span>
    </div>
  </div>
</template>

<script>
// *Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BAvatar,
  BMedia,
  BProgress,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'

// *Utils
import client from '@/libs/http/axios-config'
import { avatarText, taskType, validateStartDate } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BAvatar,
    BMedia,
    BProgress,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      // State
      isLoading: true,
      // Basic Data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      contentType: {},

      // Exam Data
      quizOverview: {},
      isAcceptTerm: false,
    }
  },
  computed: {
    quizStatus() {
      let status = 'isUnavailable'

      if (
        validateStartDate(
          this.quizOverview.start_date,
          this.quizOverview.end_date
        )
      ) {
        if (this.quizOverview.session_exam_students.length === 0) {
          status = 'isNotStarted'
        } else {
          if (this.quizOverview.session_exam_students[0].end_time === null) {
            status = 'isStarted'
          }
          if (this.quizOverview.session_exam_students[0].end_time !== null) {
            status = 'isFinished'
          }
        }
      }

      return status
    },
  },
  async mounted() {
    await client
      .get(
        `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams`
      )
      .then((response) => {
        this.quizOverview = response.data.data.taskExam[0]
        this.contentType = response.data.data.examQuestion.type

        this.isLoading = false
      })
  },
  methods: {
    avatarText,
    taskType,
    validateStartDate,
    checkAuthorizeRole,
    startQuiz() {
      client
        .post(
          `students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/${this.taskId}`
        )
        .then((response) => {
          // store to local storage
          const quizSession = {
            data: [],
            quiz_overview: {
              task_id: this.quizOverview.id,
              quiz_name: this.quizOverview.name,
              quiz_description: this.quizOverview.description,
              quiz_duration: this.quizOverview.time,
              quiz_type: this.contentType,
              quiz_start_date: this.quizOverview.start_date,
              quiz_end_date: this.quizOverview.quiz_end_date,
              quiz_classroom: {
                id: this.classroomId,
                name: this.quizOverview.classroom.name,
              },
              quiz_teacher: {
                id: this.quizOverview.classroom.teacher.id,
                name: this.quizOverview.classroom.teacher.name,
                avatar: this.quizOverview.classroom.teacher.avatar,
                school_name: this.quizOverview.classroom.teacher.school.name,
              },
              quiz_question: response.data.data.class_content.exam_questions,
              quiz_session: response.data.data.studentSession,
              quiz_last_viewed_index: 0,
            },
          }

          this.saveLocalSession(quizSession)
          this.saveServerSession(quizSession)

          this.$router.push({
            name: checkAuthorizeRole('classroom-detail-task-quiz'),
            params: {
              id: this.classroomId,
              contentId: this.contentId,
              taskId: this.taskId,
              contentType: this.contentType,
            },
          })
        })
    },
    saveLocalSession(quizSession) {
      localStorage.setItem('quizSession', JSON.stringify(quizSession))
    },
    async saveServerSession(quizSession) {
      const data = new FormData()
      data.append('data', JSON.stringify(quizSession))
      data.append('_method', 'PUT')

      await client
        .post(
          `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/${this.taskId}/update-session`,
          data
        )
        .then((response) => {
          console.log('session saved')
        })
    },
  },
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}

.quiz-overview-card {
  li {
    line-height: 1.6rem;
  }
}
</style>
